const myProjects = [
    {
        id: 0,
        name: "RG Language",
        technologies: ["MERN stack", "Typescript", "Passport.js", "bcrypt"],
        imageSource: "imgs/rg-language-screenshot-shotsnapp.png",
        imageAlt: "rg language",
        projectLink: "https://rg-language.herokuapp.com/",
        repoLink: "https://github.com/rsg71/RG_Language"
    },
    {
        id: 1,
        name: "Table Turner",
        technologies: ["MERN stack", "Passport.js", "bcrypt"],
        imageSource: "imgs/tableturner_menu-iPad-Air-2.png",
        imageAlt: "table turner",
        projectLink: "https://table-turner.herokuapp.com",
        repoLink: "https://github.com/rsg71/Table-Turner"
    },
    {
        id: 2,
        name: "Restaurant Finder",
        technologies: ["MySQL", "Express", "Node", "Passport", "Javascript", "Google Maps API"],
        imageSource: "imgs/restaurant-finder-shotsnapp-screen.png",
        imageAlt: "restaurant finder",
        projectLink: "https://find-restaurant42.herokuapp.com/",
        repoLink: "https://github.com/rsg71/Find-Restaurant"
    },
    {
        id: 3,
        name: "Budget Tool",
        technologies: ["Javascript", "Express", "Node"],
        imageSource: "imgs/budget_tool_shotsnapp.png",
        imageAlt: "budget too",
        projectLink: "https://budget-tracker-rsg.herokuapp.com/",
        repoLink: "https://github.com/rsg71/Budget_Tracker"
    },
    {
        id: 4,
        name: "Weather App",
        technologies: [" Javascript", "Open Weather API"],
        imageSource: "imgs/weather-app-rotated-blackbackground2.png",
        imageAlt: "weather app",
        projectLink: "https://rsg71.github.io/Weather-App/",
        repoLink: "https://github.com/rsg71/Weather-App"
    },

    {
        id: 5,
        name: "Employee Tracker",
        technologies: ["Javascript"],
        imageSource: "imgs/employee-tracker-shotsnapp.png",
        imageAlt: "employee tracker",
        projectLink: "https://github.com/rsg71/Employee_Tracker",
        repoLink: "https://github.com/rsg71/Employee_Tracker"
    },
    {
        id: 7,
        name: "Blackjack",
        technologies: ["Typescript", "React"],
        imageSource: "imgs/Blackjack.PNG",
        imageAlt: "blackjack",
        projectLink: "https://rsg71.github.io/Blackjack/",
        repoLink: "https://github.com/rsg71/Blackjack"
    },
    {
        id: 8,
        name: "Work Day Scheduler",
        technologies: [" Javascript", "Moment.js"],
        imageSource: "imgs/scheduler-window.png",
        imageAlt: "work scheduler",
        projectLink: "https://rsg71.github.io/Work-Scheduler/",
        repoLink: "https://github.com/rsg71/Work-Scheduler"
    },
    {
        id: 9,
        name: "Mario",
        technologies: ["Javascript, Kaboom.js"],
        imageSource: "imgs/mario-capture.PNG",
        imageAlt: "mario",
        projectLink: "https://rsg71.github.io/mario-platform/",
        repoLink: "https://github.com/rsg71/mario-platform"
    },
    {
        id: 10,
        name: "Password Generator",
        technologies: ["Javascript"],
        imageSource: "imgs/password-generator-shotsnapp.png",
        imageAlt: "password generator",
        projectLink: "https://rsg71.github.io/Password-Generator/",
        repoLink: "https://github.com/rsg71/Password-Generator"
    },
    {
        id: 11,
        name: "Brewery Finder",
        technologies: ["Javascript", "Yelp API", "OpenBreweryDB API", "Google Maps API"],
        imageSource: "imgs/brewery-finder-shotsnapp.png",
        imageAlt: "brewery finder",
        projectLink: "https://rsg71.github.io/Brewery-Finder/",
        repoLink: "https://github.com/rsg71/Brewery-Finder"
    },


]


export default myProjects;